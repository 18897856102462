@use '@angular/material' as mat;

.blue{
  color: #019FC3;
}
.green{
  color: #00A354
}

$primary: (
  100: #32b274,
  500: #00A354,
  600: #1f551b,
  700: #163a13,
  contrast: (
    500: white,
    600: white
  )
);

$accent: (
  100: #57bfd6,
  500: #019FC3,
  600: #0162C3,
  700: #004589,
  contrast: (
    500: white,
    600: white
  )
);

$warn: (
  100: #e7c952,
  500: #FFCC00,
  600: #FF8000,
  700: #b85c00,
  contrast: (
    500: white,
    600: white
  )
);

$my-app-primary: mat.define-palette($primary);
$my-app-accent:  mat.define-palette($accent);
$my-app-warn:    mat.define-palette($warn);

$my-app-theme: mat.define-light-theme($my-app-primary, $my-app-accent, $my-app-warn);
@include mat.all-component-themes($my-app-theme);

* {
  outline: none !important;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif }

.mat-button-focus-overlay { background-color: transparent!important; }

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
